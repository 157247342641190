import $ from "jquery";

export default class DataView {
    constructor() {
        $(document).on('click', '.js-rowItem', this.onRowClick.bind(this));
        $(document).on('click', '.js-rowItem input', this.onInputClick.bind(this));
        $(document).on('change', '.js-rowItem input', this.onInputChange.bind(this));
    }

    onInputClick(e) {
        e.stopPropagation();
    }

    onInputChange(e) {
        let inp = $(e.currentTarget);
        let tr = inp.closest('tr');
        inp.is(':checked') ? tr.addClass('selected') : tr.removeClass('selected');
    }

    onRowClick(e) {
        let inp = $(e.currentTarget).find('input');
        inp.prop('checked', !inp.is(':checked'));
        inp.trigger('change');
    }
}